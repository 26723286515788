import { Formik, Form, FormikHelpers } from 'formik';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { TextInput } from '../../../TextInput/TextInput';
import moment from 'moment';
import { DatePicker } from '../../../DatePicker/DatePicker';
import './Register.form.css';


export interface Values {
    email: string;
    password: string;
    phone: string;
    firstName: string;
    lastName: string;
    birthDate?: Date;
}

export function RegisterForm({ disabled, onSubmit }: { disabled: boolean, onSubmit: (values: Values, { setSubmitting }: FormikHelpers<Values>) => void }): JSX.Element {
    return (<div className='formContainer'>
        <Formik initialValues={{
        email: '',
        password: '',
        phone: '',
        firstName: '',
        lastName: '',
    }}
    validationSchema={Yup.object({
        email: Yup.string().email('Invalid format')
        .required('Email is required').trim(),
        phone: Yup.string().matches(/\+\d{10,15}/g, 'Invalid format')
        .required('Phone is required')
        .max(16, 'Invalid length').trim(),
        password: Yup.string().required('Password is required').trim(),
        firstName: Yup.string()
        .required('First name is required')
        .matches(/[A-Z][a-z]+/gm, 'First name must start with uppercase letter')
        .trim(),
        lastName: Yup.string()
        .required('Last name is required')
        .matches(/[A-Z][a-z]+/gm, 'Last name must start with uppercase letter')
        .trim(),
        birthDate: Yup.date()
        .max(moment().subtract(18, 'years').toDate(), 'You must be 18 or older')
        .required('Birth date is required'),
    })}
    onSubmit={onSubmit}>
      <Form>
        <fieldset disabled={disabled}>
        <TextInput label="Email" name="email" type="text" placeholder="test@test.com"/>
            <TextInput label="Phone" name="phone" type="text" placeholder="+380952312345"/>
            <TextInput label="Password" name="password" type="password" placeholder="Wow much secret"/>
            <TextInput label="First name" name="firstName" type="text" placeholder="John"/>
            <TextInput label="Last name" name="lastName" type="text" placeholder="Holmes"/>
            <DatePicker label="Birth Date" name="birthDate" type="date" min="1900-01-01" max={moment().subtract(18, 'years').format('YYYY-MM-DD')} placeholder="2004-01-01"/>
            <Button type="submit">Register</Button>
          </fieldset>  
      </Form>
    </Formik>
    </div>);
}
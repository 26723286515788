import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { GenericErrors } from '../../../types/error';

export interface LoginState {
  user: {
    login: string;
    password: string;
  };
  loggingIn: boolean;
}

const initialState: LoginState = {
  user: {
    login: '',
    password: '',
  },
  loggingIn: false,
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    initializeLogin: () => initialState,
    updateField: (
      state,
      { payload: { name, value } }: PayloadAction<{ name: keyof LoginState['user']; value: string }>
    ) => {
      state.user[name] = value;
    },
    updateErrors: (state, { payload: errors }: PayloadAction<GenericErrors>) => {
      state.loggingIn = false;
      toast(errors.error, { type: 'error' });
    },
    startLoginIn: (state) => {
      state.loggingIn = true;
    },
  },
});

export const { initializeLogin, updateField, updateErrors, startLoginIn } = slice.actions;

export default slice.reducer;

import { array, Decoder, number, object, string } from "decoders";

export interface Audio {
  id: number;
  name: string;
  chapter: number;
}

export enum SortingOptions {
  Az = 'a-z',
  Za = 'z-a',
  CHAPTER_DESC = 'З останнього розділу',
  CHAPTER_ASC = 'З першого розділу',
}

export const audioFilesDecoder: Decoder<{ audio: Audio[]}> = object({
  audio: array(object({
    id: number,
    name: string,
    chapter: number,
  })),
});

import { Err, Ok, Result } from "@hqoss/monads";
import axios, { AxiosError } from "axios";
import settings from "../config/settings";
import { Book, bookDecoder, BookLevel, booksDecoder, SortingOptions as BookSortingOptions } from "../types/book";
import { GenericErrors, genericErrorsDecoder } from "../types/error";
import { GetAudioFilters } from "../types/get.audio.filters";
import { GetBooksFilters } from "../types/get.books.filters";
import { loginRegisterDecoder, User } from "../types/user";
import { Audio, audioFilesDecoder, SortingOptions as AudioSortingOptions } from '../types/audio';

axios.defaults.baseURL = settings.baseApiUrl;

const commonErrorHandler = (e: Error): Result<any, GenericErrors> => {
  console.log(e);
  const axiosErr = e as AxiosError;
  if (axiosErr.response && axiosErr.response.status !== 500 && axiosErr.response.data) {
    return Err(genericErrorsDecoder.verify(axiosErr.response.data));
  }

  return Err({ error: 'Network error, try again' });
}

const blobDownloadErrorHandler = (e: Error): Result<any, GenericErrors> => {
  console.log(e);
  const axiosErr = e as AxiosError;
  if (axiosErr.response && axiosErr.response.status !== 500) {
    return Err({ error: axiosErr.message });
  }

  return Err({ error: 'Network error, try again' });
}

export async function register(user: User): Promise<Result<string, GenericErrors>> {
    try {
        const { data } = await axios.post('auth/register', user);

        return Ok(loginRegisterDecoder.verify(data).token);
    } catch (e) {
        return commonErrorHandler(e as AxiosError);
    }
}

export async function login(login: string, password: string): Promise<Result<string, GenericErrors>> {
    try {
        const { data } = await axios.post('auth/login', { login, password });

        return Ok(loginRegisterDecoder.verify(data).token);
    } catch (e) {
      return commonErrorHandler(e as AxiosError);
    }
}

export async function getBooks(filters: GetBooksFilters): Promise<Result<Book[], GenericErrors>> {
  const sortParams = (() => {
    switch (filters.sortBy) {
      case BookSortingOptions.Az:
        return { orderBy: 'name', orderDirection: 'ASC' };
      case BookSortingOptions.Za:
        return { orderBy: 'name', orderDirection: 'DESC' };
      case BookSortingOptions.N1To5:
        return { orderBy: 'level', orderDirection: 'ASC' };
      case BookSortingOptions.N5To1:
        return { orderBy: 'level', orderDirection: 'DESC' };
    }
  })();

  try {
    const { data } = await axios.get('v1/books', { params: {
      ...(filters.level !== BookLevel.ALL ? { level: filters.level }: {}),
      ...sortParams,
    } });

    return Ok(booksDecoder.verify(data).books);
  } catch (e) {
    return commonErrorHandler(e as AxiosError);
  }
}

export async function getBookById(bookId: number): Promise<Result<Book, GenericErrors>> {
  try {
    const { data } = await axios.get(`v1/books/${bookId}`);

    return Ok(bookDecoder.verify(data));
  } catch (e) {
    return commonErrorHandler(e as AxiosError);
  }
}

export async function getAudio(bookId: number, filters: GetAudioFilters): Promise<Result<Audio[], GenericErrors>> {
  console.log(filters);
  const sortParams = (() => {
    switch (filters.sortBy) {
      case AudioSortingOptions.Az:
        return { orderBy: 'name', orderDirection: 'ASC' };
      case AudioSortingOptions.Za:
        return { orderBy: 'name', orderDirection: 'DESC' };
      case AudioSortingOptions.CHAPTER_ASC:
        return { orderBy: 'chapter', orderDirection: 'ASC' };
      case AudioSortingOptions.CHAPTER_DESC:
        return { orderBy: 'chapter', orderDirection: 'DESC' };
    }
  })();

  try {
    const { data } = await axios.get(`v1/audio/${bookId}`, { params: {
      ...(filters.chapter !== -1 ? { chapter: filters.chapter }: {}),
      ...sortParams,
    } });

    return Ok(audioFilesDecoder.verify(data).audio);
  } catch (e) {
    return commonErrorHandler(e as AxiosError);
  }
}

export async function downloadBook(id: number): Promise<Result<Blob, GenericErrors>> {
  try {
    const response = await axios.get(`v1/books/${id}/download`, {
      responseType: 'blob',
    });

    return Ok(response.data);
  } catch (e) {
    return blobDownloadErrorHandler(e as AxiosError);
  }
}

import { dispatchOnCall } from "../../../state/store";
import { useStoreWithInitializer } from "../../../state/storeHooks";
import { ContainerPage } from "../../ContainerPage/ContainerPage";
import { RegisterForm, Values as RegisterFormValues } from "./Forms/Register.form";
import { initializeRegister, startRegister, updateErrors } from "./Register.slice";
import { store } from '../../../state/store';
import { loadUserIntoApp, User } from "../../../types/user";
import { register } from "../../../services/api.service";
import { toast } from "react-toastify";

export function Register() {
  const { registring } = useStoreWithInitializer(({ register }) => register, dispatchOnCall(initializeRegister()));
  
  return (
    <div className='auth-page'>
      <ContainerPage>
        <div className='col-md-6 offset-md-4 col-xs-12'>
          <h1 className='text-xs-center'>Registration</h1>
          <RegisterForm
            disabled={registring}
            onSubmit={registerUser}
          />
        </div>
      </ContainerPage>
    </div>
  );
}

async function registerUser(values: RegisterFormValues) {
  console.log(values);
  if (store.getState().register.registring) return;
  store.dispatch(startRegister());

  const result = await register(values as User);

  result.match({
    ok: (token: string) => {
      loadUserIntoApp({ token } as any as User);
      toast('Registration successful', { type: 'success', icon: '🚀' });
    },
    err: (e) => {
      store.dispatch(updateErrors(e));
    },
  });  
}

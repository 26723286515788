import { array, boolean, Decoder, number, object, oneOf, string } from "decoders";

export enum BookLevel {
  ALL = 'all',
  N1 = 'n1',
  N2 = 'n2',
  N3 = 'n3',
  N4 = 'n4',
  N5 = 'n5',
}

export enum SortingOptions {
  Az = 'a-z',
  Za = 'z-a',
  N5To1 = 'N5-N1',
  N1To5 = 'N1-N5',
}

export interface Book {
  id: number;
  firstChapter: number;
  chapters: number;
  name: string;
  level: BookLevel;
  isAudioAvailable: boolean;
  isVideoAvailable: boolean;
  isDownloadAvailable: boolean;
}

export const bookDecoder: Decoder<Book> = object({
  id: number,
  firstChapter: number,
  chapters: number,
  name: string,
  level: oneOf(Object.values(BookLevel)),
  isAudioAvailable: boolean,
  isVideoAvailable: boolean,
  isDownloadAvailable: boolean,
});

export const booksDecoder: Decoder<{ books: Book[]}> = object({
  books: array(bookDecoder),
});

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SyntheticEvent } from "react";
import { toast } from "react-toastify";
import settings from "../../config/settings";
import { store } from "../../state/store";
import { Audio as AudioFile } from "../../types/audio";

export interface PlayerState {
  isPlaying: boolean;
  audioFiles: AudioFile[];
  currentFileIndex: number;
  isVisible: boolean;
  audioElemReference: HTMLAudioElement;
  elapsedTime: number;
  totalTime: number;
}

const initialState: PlayerState = {
  isPlaying: false,
  audioFiles: [],
  currentFileIndex: 0,
  isVisible: false,
  audioElemReference: new Audio(),
  totalTime: 0,
  elapsedTime: 0,
}

const slice = createSlice({
  name: 'audioPlayer',
  initialState,
  reducers: {
    initializeAudioPlayer: () => initialState,
    setAudioFiles: (state, { payload: audioFiles }: PayloadAction<AudioFile[]>) => {
      state.audioFiles = audioFiles;
    },
    setCurrentFile: (state, { payload: currentFileIndex }: PayloadAction<number>) => {
      state.currentFileIndex = currentFileIndex;
      const currentFile = state.audioFiles[currentFileIndex];
      state.audioElemReference.onerror = (ev: Event | string) => {
        console.log('Got audio player error:');
        console.log((ev as unknown as SyntheticEvent<HTMLAudioElement>)?.currentTarget.error);
        store.dispatch(setVisibility(false));
        toast('Oops!Failed to play audio, try again later', { type: 'error', icon: '🥴' });
      }
      const srcUrl = state.audioElemReference.src = `${settings.baseApiUrl}v1/audio/${currentFile.id}/download`;
      state.audioElemReference.src = srcUrl;
    },
    setCallbacks: (state) => {
      state.audioElemReference.onended = () => store.dispatch(switchToNextAudio());
      state.audioElemReference.onpause = () => store.dispatch(setIsPlaying(false));
      state.audioElemReference.onplay = () => store.dispatch(setIsPlaying(true));
      state.audioElemReference.ontimeupdate = () => store.dispatch(updateTotalAndElapsedTime());
    },
    setIsPlaying: (state, { payload: isPlaying }: PayloadAction<boolean>) => {
      state.isPlaying = isPlaying;
      if (isPlaying) {
        state.audioElemReference.play();
      } else {
        state.audioElemReference.pause();
      }
    },
    togglePlaying: (state) => {
      state.isPlaying = !state.isPlaying;

      if (state.isPlaying) {
        state.audioElemReference.play();
      } else {
        state.audioElemReference.pause();
      }
    },
    setVisibility: (state, { payload: isVisible }: PayloadAction<boolean>) => {
      state.isVisible = isVisible;
    },
    switchToPrevAudio: (state) => {
      if (state.currentFileIndex !== 0) {
        state.currentFileIndex -= 1;
        const currentFile = state.audioFiles[state.currentFileIndex];
        const srcUrl = state.audioElemReference.src = `${settings.baseApiUrl}v1/audio/${currentFile.id}/download`;
        state.audioElemReference.src = srcUrl;
        state.audioElemReference.play();
      }
    },
    switchToNextAudio: (state) => {
      if (state.currentFileIndex < state.audioFiles.length - 1) {
        state.currentFileIndex += 1;
        const currentFile = state.audioFiles[state.currentFileIndex];
        const srcUrl = state.audioElemReference.src = `${settings.baseApiUrl}v1/audio/${currentFile.id}/download`;
        state.audioElemReference.src = srcUrl;
        state.audioElemReference.play();
      }
    },
    updateTotalAndElapsedTime: (state) => {
      state.elapsedTime = state.audioElemReference.currentTime;
      state.totalTime = state.audioElemReference.duration;
    },
    seekTo: (state, { payload: time }: PayloadAction<number>) => {
      console.log(`current time before seek: ${state.audioElemReference.currentTime}`);
      state.audioElemReference.currentTime = time;
      console.log(`current time after seek: ${state.audioElemReference.currentTime}`);
    },
  }
});

export const { initializeAudioPlayer, setAudioFiles, setCurrentFile, setCallbacks, setIsPlaying, togglePlaying, setVisibility, switchToPrevAudio, switchToNextAudio, updateTotalAndElapsedTime, seekTo } = slice.actions;
  
export default slice.reducer;

import { store } from '../state/store';
import axios from 'axios';
import { loadUser } from '../components/App/App.slice';
import { Decoder, object, string } from 'decoders';

export interface User {
  email: string;
  token?: string;
  password?: string;
  phone: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
}

export function loadUserIntoApp(user: User) {
  localStorage.setItem('token', user.token!);
  axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
  store.dispatch(loadUser(user));
}

export const loginRegisterDecoder: Decoder<{ token: string}> = object({
  token: string,
});

import { Formik, Form, FormikHelpers } from 'formik';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { TextInput } from '../../../TextInput/TextInput';
import './Login.form.css';

export interface Values {
    login: string;
    password: string;
}

export function LoginForm({ disabled, onSubmit }: { disabled: boolean, onSubmit: (values: Values, { setSubmitting }: FormikHelpers<Values>) => void }): JSX.Element {
    return (<div className='formContainer'>
        <Formik initialValues={{
        login: '',
        password: '',
    }}
    validationSchema={Yup.object({
        login: Yup.string().matches(/\+\d{10,15}/g, 'Invalid format')
        .required('Login is required')
        .max(16, 'Invalid length'),
        password: Yup.string().required('Password is required'),
    })}
    onSubmit={onSubmit}>
      <Form>
        <fieldset disabled={disabled}>
        <TextInput label="Login" name="login" type="text" placeholder="+380952312345"/>
            <TextInput label="Password" name="password" type="password" placeholder="Wow much secret"/>
            <Button type="submit">Log In</Button>
          </fieldset>    
      </Form>
    </Formik>
    </div>);
}
import ContentLoader from 'react-content-loader';

export function ListSkeletonLoader(props: any) {
  return (
    <ContentLoader
      height={600}
      width={'100%'}
      primaryColor="#d9d9d9"
      secondaryColor="#ecebeb"
      {...props}
    >
      <rect x="15" y="12" rx="3" ry="3" width="123" height="7" />
      <rect x="15" y="152" rx="3" ry="3" width="171" height="6" />
      <circle cx="250" cy="30" r="15" />
      <circle cx="300" cy="30" r="15" />
      <circle cx="350" cy="30" r="15" />
      <rect x="15" y="117" rx="3" ry="3" width="123" height="7" />
      <rect x="15" y="222" rx="3" ry="3" width="123" height="7" />
      <circle cx="250" cy="140" r="15" />
      <circle cx="300" cy="140" r="15" />
      <circle cx="350" cy="140" r="15" />
      <rect x="15" y="48" rx="3" ry="3" width="171" height="6" />
      <rect x="15" y="257" rx="3" ry="3" width="171" height="6" />
      <circle cx="250" cy="240" r="15" />
      <circle cx="300" cy="240" r="15" />
      <circle cx="350" cy="240" r="15" />
    </ContentLoader>
  )
}

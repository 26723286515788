import { Audio } from "../../types/audio";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import './AudioListItem.css';

export function AudioListItem({ audio, onAudioPlay }: { audio: Audio; onAudioPlay: (id: number) => void }) {
  return (
    <li className="bookListItem list-group-item">
      <div className="audioLeftSide">
        <h4>{audio.name}</h4>
        <h6 style={{ color: 'grey' }}>Розділ {audio.chapter}</h6>
      </div>
      <div className="audioRightSide">
        <FontAwesomeIcon size='2x' color="#c73f2e" onClick={() => onAudioPlay(audio.id)} icon={faPlay}/>
      </div>
    </li>
  );
}

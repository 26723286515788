import { useField, FieldHookConfig } from 'formik';

export function TextInput({label, ...props}: FieldHookConfig<string> & { label: string }) {
    const [field, meta] = useField(props);

    return (
        <div className='textInputContainer'>
            <label htmlFor={props.id || props.name}>{label}</label>
            <input className='form-control' {...field} placeholder={props.placeholder} type={props.type} />
            {meta.touched && meta.error ? (
                <div className='text-danger'>{meta.error}</div>
            ) : null}
        </div>
    );
}
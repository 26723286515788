import { dispatchOnCall } from "../../../state/store";
import { useStoreWithInitializer } from "../../../state/storeHooks";
import { ContainerPage } from "../../ContainerPage/ContainerPage";
import { initializeLogin, startLoginIn, updateErrors } from "./Login.slice";
import { store } from '../../../state/store';
import { loadUserIntoApp, User } from "../../../types/user";
import './Login.css';
import { LoginForm, Values as LoginFormValues } from "./Forms/Login.form";
import { login } from "../../../services/api.service";

export function Login() {
  const { loggingIn } = useStoreWithInitializer(({ login }) => login, dispatchOnCall(initializeLogin()));

  return (
    <div className='auth-page'>
      <ContainerPage>
        <div className='col-md-6 offset-md-4 col-xs-12'>
          <h1 className='text-xs-center'>Login</h1>
          <LoginForm
            disabled={loggingIn}
            onSubmit={signIn}
          />
        </div>
      </ContainerPage>
    </div>
  );
}

async function signIn(values: LoginFormValues) {
  console.log(values);
  const { login: l, password } = values;
  if (store.getState().login.loggingIn) return;
  store.dispatch(startLoginIn());

  const result = await login(l, password);

  result.match({
    ok: (token: string) => {
      loadUserIntoApp({ token } as any as User);
    },
    err: (e) => {
      store.dispatch(updateErrors(e));
    },
  }); 
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Book } from "../../../types/book";
import { GenericErrors } from "../../../types/error";

export interface BookSelectorState {
  books: Book[];
  booksLoading: boolean;
  errors: GenericErrors;
}

const initialState: BookSelectorState = {
  books: [],
  booksLoading: true,
  errors: {},
};

const slice = createSlice({
  name: 'bookSelector',
  initialState,
  reducers: {
    initializeSettings: () => initialState,
    updateErrors: (state, { payload: errors }: PayloadAction<GenericErrors>) => {
      state.errors = errors;
    },
    loadBooks: (state, { payload: books }: PayloadAction<Book[]>) => {
      state.books = books;
      state.booksLoading = false;
    },
    appendBook: (state, { payload: book }: PayloadAction<Book>) => {
        state.books.push(book);
    },
    updateBook: (state, { payload: book }: PayloadAction<Book>) => {
        state.books[state.books.findIndex(b => b.id === book.id)] = book;
    }
  },
});

export const { initializeSettings, updateErrors, loadBooks, appendBook, updateBook } = slice.actions;

export default slice.reducer;

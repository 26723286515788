import { Action, configureStore } from "@reduxjs/toolkit";
import app from '../components/App/App.slice';
import login from '../components/Pages/Login/Login.slice';
import register from '../components/Pages/Register/Register.slice';
import bookSelector from '../components/Pages/BookSelector/BookSelector.slice';
import audioSelector from '../components/Pages/AudioSelector/AudioSelector.slice';
import bookFilters from '../components/BookFiltersPanel/BooksFiltersPanel.slice';
import audioFilters from '../components/AudioFiltersPanel/AudioFiltersPanel.slice';
import audioPlayer from '../components/AudioPlayer/AudioPlayer.slice';

const middlewareConfiguration = { serializableCheck: false };

export const store = configureStore({
  reducer: { app, login, register, bookSelector, audioSelector, bookFilters, audioFilters, audioPlayer },
  devTools: {
    name: 'Nihongo',
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(middlewareConfiguration),
});
export type State = ReturnType<typeof store.getState>;

export function dispatchOnCall(action: Action) {
  return () => store.dispatch(action);
}

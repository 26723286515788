import { dispatchOnCall, store } from "../../state/store";
import { useStoreWithInitializer } from "../../state/storeHooks";
import { SortingOptions } from "../../types/audio";
import { initializeAudioFilters, setFilters } from "./AudioFiltersPanel.slice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpAZ, faFilter } from '@fortawesome/free-solid-svg-icons';
import './AudioFiltersPanel.css';
import { SyntheticEvent } from "react";

export function AudioFiltersPanel({firstChapter, numberOfChapters, onChange }: { firstChapter: number; numberOfChapters: number; onChange: () => void }) {
  const { chapter, sortBy } = useStoreWithInitializer(({ audioFilters }) => audioFilters, dispatchOnCall(initializeAudioFilters()));

  const filtersUpdatedHandler = function(event: SyntheticEvent<HTMLSelectElement>) {
    if (event.currentTarget.id === 'chapters') {
      store.dispatch(setFilters({ chapter: parseInt(event.currentTarget.value), sortBy }));
    } else {
      store.dispatch(setFilters({ chapter, sortBy: event.currentTarget.value as SortingOptions }));
    }

    onChange();
  }

  return (
    <div className="audioFilterPanel">
      <div className="chapterFilterContainer">
        <FontAwesomeIcon size='xl' color='#c73f2e' icon={faFilter} />
        <select className="form-select" name="chapters" id="chapters" onChange={filtersUpdatedHandler}>
          <option key={-1} value={-1}>Усі розділи</option>
          {Array.from({length: numberOfChapters}, (_, i) => i + firstChapter).map(c => <option key={c} value={c}>{c}</option>)}
        </select>
      </div>
      <div className="sortingSelectorContainer">
        <FontAwesomeIcon size='xl' color='#c73f2e' icon={faArrowUpAZ} />
        <select defaultValue={SortingOptions.CHAPTER_ASC} className="form-select" name="sortBy" id="sorting" onChange={filtersUpdatedHandler}>
          {Object.entries(SortingOptions).map(([key, value]) => <option key={value} value={value}>{value}</option>)}
        </select>
      </div>
    </div>
  );
}

import React, { Fragment } from 'react';
import { BrowserRouter, Navigate, useLocation } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { store } from '../../state/store';
import { useStoreWithInitializer } from '../../state/storeHooks';
import { User } from '../../types/user';
import { Login } from '../Pages/Login/Login';
import { endLoad, loadUser } from './App.slice';
import axios from 'axios';
import { Register } from '../Pages/Register/Register';
import { BookSelector } from '../Pages/BookSelector/BookSelector';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AudioSelector } from '../Pages/AudioSelector/AudioSelector';
import { AudioPlayer } from '../AudioPlayer/AudioPlayer';

export function App() {
  const { loading } = useStoreWithInitializer(({ app }) => app, load);
  const { isVisible } = useStoreWithInitializer(({ audioPlayer }) => audioPlayer, loadAudioPlayer);

  return (
    <BrowserRouter>
      {!loading && (
        <Fragment>
          <ToastContainer hideProgressBar={true} theme={'dark'}/>
          <Routes>
            <Route path='/login' element={<RequireNoAuth>
              <Login/>
            </RequireNoAuth>}/>
            <Route path='/register' element={<RequireNoAuth>
              <Register/>
            </RequireNoAuth>}/>
            <Route path='/' element={<RequireNoAuth>
                <BookSelector/>
              </RequireNoAuth>}/>
            <Route path='/audio/:id' element={<RequireNoAuth>
                <AudioSelector/>
              </RequireNoAuth>}/>
          </Routes>
        </Fragment>
      )}
      {loading && (
        <h1>Loading...</h1>
      )}
      {isVisible && (<AudioPlayer></AudioPlayer>)}
    </BrowserRouter>
  );
}

async function load() {
  const token = localStorage.getItem('token');
  if (!store.getState().app.loading || !token) {
    store.dispatch(endLoad());
    return;
  }
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    store.dispatch(loadUser({ token } as any as User));
  } catch {
    store.dispatch(endLoad());
  }
}

async function loadAudioPlayer() {
  // TODO: Can be used to reload audio player state after tab reload using local storage maybe?
}

function RequireNoAuth({ children }: { children: JSX.Element }) {
  const isAuthenticated = store.getState().app.user.isSome();

  if (isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return children;
};

function RequireAuth({ children }: { children: JSX.Element }) {
  const isNotAuthenticated = store.getState().app.user.isNone();
  const location = useLocation();


  if (isNotAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children;
};

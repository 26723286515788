import { store } from "../../state/store";
import { useStore } from "../../state/storeHooks";
import { AudioControls } from "../AudioControls/AudioControls";
import { AudioSeekBar } from "../AudioSeekBar/AudioSeekBar";
import './AudioPlayer.css';
import { seekTo, switchToNextAudio, switchToPrevAudio, togglePlaying } from "./AudioPlayer.slice";

export function AudioPlayer() {
  const { isPlaying, audioFiles, currentFileIndex, elapsedTime, totalTime } = useStore(({ audioPlayer }) => audioPlayer);
  const currentFile = audioFiles[currentFileIndex];

  return (<div className="audioPlayerContainer">
    <div className="audioPlayerLeftSide">
      <h4>{currentFile.name}</h4>
      <h6 style={{ opacity: '55%' }}>Розділ {currentFile.chapter}</h6>
    </div>
    <AudioSeekBar elapsedTime={elapsedTime} totalTime={totalTime} onSeek={onSeek}></AudioSeekBar>
    <AudioControls isPlaying={isPlaying} onPlayPause={onPlayPause} onPrev={onPrev} onNext={onNext}></AudioControls>
  </div>);
}

function onPlayPause() {
  store.dispatch(togglePlaying());
}

function onPrev() {
  store.dispatch(switchToPrevAudio());
}

function onNext() {
  store.dispatch(switchToNextAudio());
}

function onSeek(time: number) {
  store.dispatch(seekTo(time));
}

import { GenericErrors } from "../../../types/error";
import { Audio } from '../../../types/audio';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AudioSelectorState {
  audioFiles: Audio[];
  audioLoading: boolean;
  errors: GenericErrors;
  bookId?: number;
  firstChapter: number;
  chapters: number;
}

const initialState: AudioSelectorState = {
  audioFiles: [],
  audioLoading: true,
  errors: {},
  firstChapter: 1,
  chapters: 1,
};

const slice = createSlice({
    name: 'audioSelector',
    initialState,
    reducers: {
      initializeAudioSelector: () => initialState,
      setBookId: (state, { payload: bookId }: PayloadAction<number>) => {
        state.bookId = bookId;
      },
      updateErrors: (state, { payload: errors }: PayloadAction<GenericErrors>) => {
        state.errors = errors;
      },
      loadAudioFilesChapters: (state, { payload: data }: PayloadAction<{ chapters: number; firstChapter: number;}>) => {
        state.chapters = data.chapters;
        state.firstChapter = data.firstChapter;
      },
      loadAudioFiles: (state, { payload: audioFiles }: PayloadAction<Audio[]>) => {
        state.audioFiles = audioFiles;
        state.audioLoading = false;
      },
      appendAudioFile: (state, { payload: audio }: PayloadAction<Audio>) => {
          state.audioFiles.push(audio);
      },
      updateAudioFile: (state, { payload: audio }: PayloadAction<Audio>) => {
          state.audioFiles[state.audioFiles.findIndex(b => b.id === audio.id)] = audio;
      }
    },
  });
  
export const { initializeAudioSelector, setBookId, updateErrors, loadAudioFiles, loadAudioFilesChapters, appendAudioFile, updateAudioFile } = slice.actions;
  
export default slice.reducer;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { GenericErrors } from "../../../types/error";

export interface RegisterState {
  registring: boolean;
}

const initialState: RegisterState = {
  registring: false,
};

const slice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    initializeRegister: () => initialState,
    updateErrors: (state, { payload: errors }: PayloadAction<GenericErrors>) => {

      state.registring = false;
      toast(errors.error, { type: 'error' });
    },
    startRegister: (state) => {
      state.registring = true;
    },
  },
});

export const { initializeRegister, updateErrors, startRegister } = slice.actions;

export default slice.reducer;

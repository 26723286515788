import { store } from "../../../state/store";
import { useStoreWithInitializer } from "../../../state/storeHooks";
import { Book } from "../../../types/book";
import { BookFiltersPanel } from "../../BookFiltersPanel/BookFiltersPanel";
import { BookListItem } from "../../BookListItem/BookListItem";
import { ContainerPage } from "../../ContainerPage/ContainerPage";
import { loadBooks, updateErrors } from "./BookSelector.slice";
import './BookSelector.css';
import { downloadBook, getBooks } from "../../../services/api.service";
import { ListSkeletonLoader } from "../../ListSkeletonLoader/ListSkeletonLoader";
import { toast } from "react-toastify";
import { GenericErrors } from "../../../types/error";
import { NavigateFunction, useNavigate } from "react-router-dom";

export function BookSelector() {
  const { booksLoading, books, errors } = useStoreWithInitializer(({ bookSelector }) => bookSelector, load);
  const navigate = useNavigate();

  return (
    <div className='bookSelectorPage'>
    <ContainerPage>
      <div className='foobar'>
        <BookFiltersPanel onChange={onFiltersChange}></BookFiltersPanel>
        { booksLoading && !errors.error && (<ListSkeletonLoader></ListSkeletonLoader>) }
        { !booksLoading && !errors.error && (<ul className="bookListContainer list-group list-group-flush">
          {books.map(b => <BookListItem navigate={navigate} onOpenAudiosPage={onOpenAudiosPage} onBookDownload={onBookDownload} key={b.id} book={b} ></BookListItem>)}
        </ul>) }
        { errors.error && (<h2>Ooops! Something bad happened, reload to try again</h2>) }
      </div>
    </ContainerPage>
  </div>
  );
}

async function load() {
  const books = await getBooks(store.getState().bookFilters);
  books.match({
    ok: (books: Book[]) => {
      store.dispatch(loadBooks(books));
    },
    err: (e: GenericErrors) => {
      console.log(e);
      store.dispatch(updateErrors(e));
    }
  });
}

async function onFiltersChange() {
  load();
}

async function onOpenAudiosPage(id: number, navigate: NavigateFunction) {
  navigate(`audio/${id}`);
}

async function onBookDownload(id: number) {
  const bookBlob = await downloadBook(id);
  bookBlob.match({
    ok: (b: Blob) => {
      const blobURL = window.URL.createObjectURL(b);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', 'file.pdf');
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
      }, 100);
    },
    err: (e: GenericErrors) => {
      console.log(e);
      toast('Oops!Failed to download book, try again later ', { type: 'error', icon: '🥴' });
    }
  })
}

import { Book } from "../../types/book";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh, faFilm, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import './BookListItem.css';
import { NavigateFunction } from "react-router-dom";

export function BookListItem({ navigate, book, onOpenAudiosPage, onBookDownload }: { navigate: NavigateFunction; book: Book; onOpenAudiosPage: (id: number, navigate: any) => void; onBookDownload: (id: number) => void }) {
  return (
    <li className="bookListItem list-group-item">
      <div className="bookLeftSide">
        <h4>{book.name}</h4>
        <h6 style={{ color: 'grey' }}>{book.level.toUpperCase()}</h6>
      </div>
      <div className="bookRightSide">
        {book.isAudioAvailable && <FontAwesomeIcon size='2x' color="#c73f2e" onClick={() => onOpenAudiosPage(book.id, navigate)} icon={faVolumeHigh}/>}
        {book.isVideoAvailable && <FontAwesomeIcon size='2x' color="#c73f2e" icon={faFilm}/>}
        {book.isDownloadAvailable && <FontAwesomeIcon size='2x' color="#c73f2e" onClick={() => onBookDownload(book.id)} icon={faFilePdf}/>}
      </div>
    </li>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortingOptions } from "../../types/audio";

export interface AudioFiltersState {
  chapter: number;
  sortBy: SortingOptions;
}

const initialState: AudioFiltersState = {
  chapter: -1,
  sortBy: SortingOptions.CHAPTER_ASC,
}

const slice = createSlice({
  name: 'audioFilters',
  initialState,
  reducers: {
    initializeAudioFilters: () => initialState,
    setFilters: (
      state,
      { payload: { chapter, sortBy } }: PayloadAction<{ chapter: number; sortBy: SortingOptions }>
    ) => {
      state.chapter = chapter;
      state.sortBy = sortBy;
    },
  },
});

export const { initializeAudioFilters, setFilters } = slice.actions;

export default slice.reducer;

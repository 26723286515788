import { useNavigate, useParams } from "react-router-dom";
import { getAudio, getBookById } from "../../../services/api.service";
import { store } from "../../../state/store";
import { useStoreWithInitializer } from "../../../state/storeHooks";
import { AudioFiltersPanel } from "../../AudioFiltersPanel/AudioFiltersPanel";
import { AudioListItem } from "../../AudioListItem/AudioListItem";
import { ContainerPage } from "../../ContainerPage/ContainerPage";
import { ListSkeletonLoader } from "../../ListSkeletonLoader/ListSkeletonLoader";
import { loadAudioFiles, loadAudioFilesChapters, setBookId, updateErrors } from "./AudioSelector.slice";
import { Audio } from '../../../types/audio';
import { GenericErrors } from "../../../types/error";
import { Book } from "../../../types/book";
import { setAudioFiles, setCallbacks, setCurrentFile, setIsPlaying, setVisibility } from "../../AudioPlayer/AudioPlayer.slice";
import './AudioSelector.css';

export function AudioSelector() {
  const { id: newBookId } = useParams();
  const navigate = useNavigate();

  if (!newBookId) {
    navigate('/');
  }

  const { audioLoading, firstChapter, chapters, audioFiles, errors } = useStoreWithInitializer(({ audioSelector }) => audioSelector, () => { store.dispatch(setBookId(newBookId as unknown as number));loadFilterChapters();load() });

  return (
    <div className="audioSelectorPage">
      <ContainerPage>
        <div className="foobar">
          <AudioFiltersPanel firstChapter={firstChapter} numberOfChapters={chapters} onChange={onFiltersChange}></AudioFiltersPanel>
          { audioLoading && !errors.error && (<ListSkeletonLoader></ListSkeletonLoader>) }
          { !audioLoading && !errors.error && (<ul className="audioListContainer list-group list-group-flush">
            {audioFiles.map(a => <AudioListItem onAudioPlay={onAudioPlay} key={a.id} audio={a} ></AudioListItem>)}
          </ul>) }
          { errors.error && (<h2>Ooops! Something bad happened, reload to try again</h2>) }
        </div>
      </ContainerPage>
    </div>
  );
}

async function loadFilterChapters() {
  const bookId = store.getState().audioSelector.bookId as number;
  const book = await getBookById(bookId);
  book.match({
    ok: (book: Book) => {
      store.dispatch(loadAudioFilesChapters({ chapters: book.chapters, firstChapter: book.firstChapter }));
    },
    err: (e: GenericErrors) => {
      console.log(e);
      store.dispatch(updateErrors(e));
    }
  });
}

async function load() {
  const audioFiles = await getAudio(store.getState().audioSelector.bookId as number, store.getState().audioFilters);
  audioFiles.match({
    ok: (audio: Audio[]) => {
      store.dispatch(loadAudioFiles(audio));
    },
    err: (e: GenericErrors) => {
      console.log(e);
      store.dispatch(updateErrors(e));
    }
  });
}

async function onFiltersChange() {
  load();
}

async function onAudioPlay(id: number) {
  const audioFiles = store.getState().audioSelector.audioFiles;
  const fileIndex = audioFiles.findIndex(f => f.id === id);
  console.log(`file is ${JSON.stringify(store.getState().audioSelector.audioFiles[fileIndex])}`);
  store.dispatch(setAudioFiles(audioFiles));
  store.dispatch(setCurrentFile(fileIndex));
  store.dispatch(setCallbacks());
  store.dispatch(setVisibility(true));
  store.dispatch(setIsPlaying(true));
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BookLevel, SortingOptions } from "../../types/book";

export interface BooksFiltersState {
  level: BookLevel;
  sortBy: SortingOptions;
}

const initialState: BooksFiltersState = {
  level: BookLevel.ALL,
  sortBy: SortingOptions.Az,
}

const slice = createSlice({
  name: 'bookFilters',
  initialState,
  reducers: {
    initializeBookFilters: () => initialState,
    setFilters: (
      state,
      { payload: { level, sortBy } }: PayloadAction<{ level: BookLevel; sortBy: SortingOptions }>
    ) => {
      state.level = level;
      state.sortBy = sortBy;
    },
  },
});

export const { initializeBookFilters, setFilters } = slice.actions;

export default slice.reducer;

import { dispatchOnCall, store } from "../../state/store";
import { useStoreWithInitializer } from "../../state/storeHooks";
import { BookLevel, SortingOptions } from "../../types/book";
import { initializeBookFilters, setFilters } from "./BooksFiltersPanel.slice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpAZ, faFilter } from '@fortawesome/free-solid-svg-icons';
import './BookFilterPanel.css';
import { SyntheticEvent } from "react";

export function BookFiltersPanel({ onChange }: { onChange: () => void }) {
  const { level, sortBy } = useStoreWithInitializer(({ bookFilters }) => bookFilters, dispatchOnCall(initializeBookFilters()));

  const filtersUpdatedHandler = function(event: SyntheticEvent<HTMLSelectElement>) {
    if (event.currentTarget.id === 'levels') {
      store.dispatch(setFilters({ level: event.currentTarget.value as BookLevel, sortBy }));
    } else {
      store.dispatch(setFilters({ level, sortBy: event.currentTarget.value as SortingOptions }));
    }

    onChange();
  }

  return (
    <div className="booksFilterPanel">
      <div className="levelFilterContainer">
        <FontAwesomeIcon size='xl' color='#c73f2e' icon={faFilter} />
        <select className="form-select" name="levels" id="levels" onChange={filtersUpdatedHandler}>
          {Object.entries(BookLevel).map(([key, value]) => value !== BookLevel.ALL ? <option key={value} value={value}>{key}</option> : <option key={value} value={value}>N1-N5</option>)}
        </select>
      </div>
      <div className="sortingSelectorContainer">
        <FontAwesomeIcon size='xl' color='#c73f2e' icon={faArrowUpAZ} />
        <select className="form-select" name="sortBy" id="sorting" onChange={filtersUpdatedHandler}>
          {Object.entries(SortingOptions).map(([key, value]) => <option key={value} value={value}>{value}</option>)}
        </select>
      </div>
    </div>
  );
}

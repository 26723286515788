import { MouseEvent, SyntheticEvent } from 'react';
import './AudioSeekBar.css';


export function AudioSeekBar({ totalTime, elapsedTime, onSeek }: { totalTime: number; elapsedTime: number; onSeek: (time: number) => void }) {
  const elapsedSeconds = Math.round(elapsedTime % 60);
  const playedTimeFormatted = `${Math.floor(elapsedTime / 60)}:${elapsedSeconds < 10 ? '0': ''}${elapsedSeconds}`;

  const remainingTime = totalTime - elapsedTime;
  const remainingSeconds = Math.round(remainingTime % 60);
  const remainingTimeFormatted = `-${Math.floor(remainingTime / 60)}:${remainingSeconds < 10 ? '0': ''}${remainingSeconds}`;
  const playedPartWidth = Math.floor(elapsedTime / totalTime * 100);
  const remainingPartWidth = 100 - playedPartWidth;

  return (
    <div  className="audioSeekBarContainer">
      <div className="barContainer" onClick={(e) => barOnClick(e, onSeek, totalTime)}>
        <div className="playedBarPart" style={{ width: `${playedPartWidth}%` }}></div>
        <div className="remainingBarPart" style={{ width: `${remainingPartWidth}%` }}></div>
      </div>
      <div className="timeContainer">
        <h6 className="playedTimeLabel">{playedTimeFormatted}</h6>
        <h6 className="remainingTimeLabel">{remainingTimeFormatted}</h6>
      </div>
    </div>
  );
}

function barOnClick(e: MouseEvent<HTMLDivElement>, seekTo: (time: number) => void, totalTime: number): void {
  const rect = e.currentTarget.getBoundingClientRect();
  const percentage = Math.round((e.clientX - rect.left) / rect.width * 100);
  const trackTime = Math.round(totalTime * (percentage / 100));

  console.log(rect);
  console.log(`% ${percentage} trackTime: ${trackTime}`);

  seekTo(trackTime);
}